
import MainTable from "@/modules/pettyCash/components/MainTable.vue";
import SearchForm from "@/modules/pettyCash/components/SearchForm.vue";

export default {
    components: {SearchForm,MainTable},
    setup() {
        const tableName = 'petty_cash'
        return {
            tableName
        }
    }
}
